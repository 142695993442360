
import { defineComponent, onMounted, ref, computed } from 'vue';
import { InvoiceReceipt, CreditCardPayment, InvoiceForReceipt } from '@/models/Accounting';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';
import _ from 'lodash';
import { generalStore } from '@/store';
import Decimal from 'decimal.js';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import InputNumber from 'primevue/inputnumber';
import DocumentHeader from '@/components/BusinessClients/DocumentHeader.vue';
import BusinessReceiptInvoicesModal from '@/components/BusinessClients/BusinessDocuments/BusinessReceiptInvoicesModal.vue';
import BusinessReceiptPaymentModal from '@/components/BusinessClients/BusinessDocuments/BusinessReceiptPaymentModal.vue';
import BusinessReceiptPelecardPaymentModal from '@/components/BusinessClients/BusinessDocuments/BusinessReceiptPelecardPaymentModal.vue';
import BusinessAccountingForTo from '@/components/BusinessClients/BusinessDocuments/BusinessAccountingForTo.vue';
import { ReceiptPaymentSteps } from '@/models/Enums';
import { AddPaymentInReceiptViewModel } from '@/models/Interfaces';
import {
    BusinessBankTransferPayment,
    BusinessCashPayment,
    BusinessCheckPayment,
    BusinessCreditCardPelecardPayment
} from '@/models/BusinessClients';
import ReceiptVue from '@/components/Accounting/Receipt.vue';

export default defineComponent({
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        invoiceReceiptId: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean
        },
        orderId: {
            type: String,
            default: ''
        }
    },
    components: {
        BusinessReceiptPaymentModal,
        BusinessReceiptPelecardPaymentModal,
        DocumentHeader,
        BusinessAccountingForTo
    },
    async setup(props) {
        const { t } = useI18n();
        const loaded = ref(false);
        const invoiceReceipt = ref(new InvoiceReceipt());
        const modalBusinessReceiptPayment = ref<InstanceType<typeof BusinessReceiptPaymentModal>>();
        const modalBusinessReceiptPelecardPayment = ref<InstanceType<typeof BusinessReceiptPelecardPaymentModal>>();
        const localCurrencyId = ref('');
        const localCurrencySymbol = ref('');
        const frameUrl = ref<string>('');
        const orderCurrencySymbol = ref('');
        const orderLeftToPay = ref(0);
        const orderCurrencyRate = ref(0);
        const orderCurrencyId = ref('');
        const tempAddPaymentInReceiptViewModel = ref();
        const isTerminalPaymentOnline = ref(false);
        const paymentCreditCardExist = computed(() => invoiceReceipt.value.creditCardPelecardPayments.length > 0);
        const paymentsExist = computed(
            () =>
                invoiceReceipt.value.creditCardPelecardPayments.length ||
                invoiceReceipt.value.cashPayments.length ||
                invoiceReceipt.value.checkPayments.length ||
                invoiceReceipt.value.bankTransferPayments.length
        );

        const invoiceReceiptHeader = computed(() => {
            if (props.isNew) {
                return t('header.new-invoice-receipt');
            } else {
                return t('accounting.invoice-receipt') + ' #' + (invoiceReceipt.value.documentNumber ?? '');
            }
        });

        const paymentsTotal = computed(() => {
            let initialValue = 0;
            initialValue = invoiceReceipt.value.creditCardPelecardPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            initialValue = invoiceReceipt.value.cashPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            initialValue = invoiceReceipt.value.checkPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            initialValue = invoiceReceipt.value.bankTransferPayments?.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total || 0) * currentValue.currencyRate,
                initialValue
            );
            return initialValue;
        });

        // NEW
        const includeVat = ref(false);
        const vatPercent = generalStore.state.vatPercent;
        const multiplyVAT = generalStore.state.multiplyVAT;
        const multiplyExcludingVAT = generalStore.state.multiplyExcludingVAT;
        const getVatAmount = () => {
            const percent = includeVat.value ? multiplyVAT : 0;
            return new Decimal(paymentsTotal.value).dividedBy(new Decimal(multiplyExcludingVAT)).mul(percent);
        };
        const getTotalExcludedVat = computed(() => {
            const amount = new Decimal(paymentsTotal.value).minus(getVatAmount());
            return amount.mul(100).dividedBy(100);
        });
        //

        const orderLeftToPayComputed = computed(() => {
            if (localCurrencyId.value == orderCurrencyId.value) {
                return orderLeftToPay.value - paymentsTotal.value;
            } else {
                return orderLeftToPay.value - paymentsTotal.value / orderCurrencyRate.value;
            }
        });
        function cancel() {
            if (props.orderId) {
                if (props.editMode) {
                    router.push({
                        name: 'invoiceReceiptView',
                        params: { invoiceReceiptId: props.invoiceReceiptId, orderId: props.orderId }
                    });
                } else {
                    router.push({ name: 'order', params: { id: props.orderId } });
                }
            }
        }
        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        async function createInvoiceReceipt() {
            swal.showLoading();
            const toSaveInvoiceReceipt: InvoiceReceipt = JSON.parse(JSON.stringify(invoiceReceipt.value));
            toSaveInvoiceReceipt.timezone = -new Date().getTimezoneOffset();
            toSaveInvoiceReceipt.totalAmount = paymentsTotal.value;
            toSaveInvoiceReceipt.vatPercent = vatPercent;
            toSaveInvoiceReceipt.vatAmount = new Decimal(getVatAmount()).toString();
            toSaveInvoiceReceipt.totalExcludedVat = getTotalExcludedVat.value.toString();
            const apiPromise = api.createInvoiceReceipt(toSaveInvoiceReceipt);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            const documentId = apiResult.data?.id;
            if (documentId) {
                await swal.fire({
                    icon: 'success',
                    title: t('alert.invoiceReceiptWasCreated')
                });
                if (props.orderId) {
                    router.push({ name: 'order', params: { id: props.orderId } });
                }
            }
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'info',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                return;
            }
            if (paymentsTotal.value == 0) {
                return;
            }
            await createInvoiceReceipt();
        };

        async function viewPdfDocument() {
            if (!invoiceReceipt.value.pdfUrl) {
                swal.showLoading();
                const apiPromise = api.getBusinessReceiptPdf(props.invoiceReceiptId);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data || apiResult.data.pdfUrl == null || apiResult.data.pdfUrlWithInvoices == null) {
                    swal.fire({
                        icon: 'error',
                        text: t('alert.pdfNotReady')
                    });
                    return;
                }
                invoiceReceipt.value.pdfUrl = apiResult.data.pdfUrl;
            }
            window.open(invoiceReceipt.value.pdfUrl, '_blank');
        }
        function removeCashPayment(index: number) {
            invoiceReceipt.value.cashPayments.splice(index, 1);
        }
        function removeCheckPayment(index: number) {
            invoiceReceipt.value.checkPayments.splice(index, 1);
        }
        function removeBankTransferPayment(index: number) {
            invoiceReceipt.value.bankTransferPayments.splice(index, 1);
        }
        function editCashPayment(index: number) {
            const openReq: AddPaymentInReceiptViewModel = {
                selectedStep: ReceiptPaymentSteps.Cash,
                cashPayment: _.clone(invoiceReceipt.value.cashPayments[index]),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: new BusinessBankTransferPayment(),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            modalBusinessReceiptPayment.value?.open(openReq);
        }
        function editCheckPayment(index: number) {
            const openReq: AddPaymentInReceiptViewModel = {
                selectedStep: ReceiptPaymentSteps.Check,
                cashPayment: new BusinessCashPayment(),
                checkPayment: _.clone(invoiceReceipt.value.checkPayments[index]),
                bankTransferPayment: new BusinessBankTransferPayment(),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            modalBusinessReceiptPayment.value?.open(openReq);
        }
        function editBankTransferPayment(index: number) {
            const openReq: AddPaymentInReceiptViewModel = {
                selectedStep: ReceiptPaymentSteps.BankTransfer,
                cashPayment: new BusinessCashPayment(),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: _.clone(invoiceReceipt.value.bankTransferPayments[index]),
                creditCardPelecardPayment: new BusinessCreditCardPelecardPayment()
            };
            modalBusinessReceiptPayment.value?.open(openReq);
        }

        async function shareDocument() {
            router.push({ name: 'sharedocument', params: { documentId: props.invoiceReceiptId } });
        }

        function openPaymentModal() {
            modalBusinessReceiptPayment.value?.open();
        }
        function CreateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                const r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }

        function addPaymentInReceipt(req: AddPaymentInReceiptViewModel) {
            if (req.selectedStep == ReceiptPaymentSteps.CreditCard) {
                if (req.cashPayment.fakeId) {
                    const findIndex = invoiceReceipt.value.creditCardPelecardPayments.findIndex(x => x.fakeId == req.cashPayment.fakeId);
                    if (findIndex >= 0) {
                        invoiceReceipt.value.creditCardPelecardPayments[findIndex] = _.clone(req.creditCardPelecardPayment);
                    }
                } else {
                    req.creditCardPelecardPayment.fakeId = CreateUUID();
                    invoiceReceipt.value.creditCardPelecardPayments.push(req.creditCardPelecardPayment);
                }
            } else if (req.selectedStep == ReceiptPaymentSteps.Cash) {
                if (req.cashPayment.fakeId) {
                    const findIndex = invoiceReceipt.value.cashPayments.findIndex(x => x.fakeId == req.cashPayment.fakeId);
                    if (findIndex >= 0) {
                        invoiceReceipt.value.cashPayments[findIndex] = _.clone(req.cashPayment);
                    }
                } else {
                    req.cashPayment.fakeId = CreateUUID();
                    invoiceReceipt.value.cashPayments.push(req.cashPayment);
                }
            } else if (req.selectedStep == ReceiptPaymentSteps.Check) {
                if (req.checkPayment.fakeId) {
                    const findIndex = invoiceReceipt.value.checkPayments.findIndex(x => x.fakeId == req.checkPayment.fakeId);
                    if (findIndex >= 0) {
                        invoiceReceipt.value.checkPayments[findIndex] = _.clone(req.checkPayment);
                    }
                } else {
                    req.checkPayment.fakeId = CreateUUID();
                    invoiceReceipt.value.checkPayments.push(req.checkPayment);
                }
            } else if (req.selectedStep == ReceiptPaymentSteps.BankTransfer) {
                if (req.bankTransferPayment.fakeId) {
                    const findIndex = invoiceReceipt.value.bankTransferPayments.findIndex(x => x.fakeId == req.bankTransferPayment.fakeId);
                    if (findIndex >= 0) {
                        invoiceReceipt.value.bankTransferPayments[findIndex] = _.clone(req.bankTransferPayment);
                    }
                } else {
                    req.bankTransferPayment.fakeId = CreateUUID();
                    invoiceReceipt.value.bankTransferPayments.push(req.bankTransferPayment);
                }
            }
        }

        async function clearCreditCard(req: BusinessCreditCardPelecardPayment) {
            swal.showLoading();
            const response = await api.PelecardInit(req.total ?? 0, req.currencyId, req.creditCardPaymentType, req.numberOfPayments);
            await q.delay(400);
            if (response.error) {
                swal.fire({
                    icon: 'error',
                    title: t('pelecard.error'),
                    text: response.errorMessage
                });
                return;
            }
            swal.close();
            if (!response.data) {
                return;
            }
            frameUrl.value = response.data.url;
            req.transactionId = response.data.transactionId ?? 0;
            const payment: AddPaymentInReceiptViewModel = {
                creditCardPelecardPayment: req,
                selectedStep: ReceiptPaymentSteps.CreditCard,
                cashPayment: new BusinessCashPayment(),
                checkPayment: new BusinessCheckPayment(),
                bankTransferPayment: new BusinessBankTransferPayment()
            };
            tempAddPaymentInReceiptViewModel.value = payment;
            modalBusinessReceiptPelecardPayment.value?.open(frameUrl.value);
        }

        function addPaymentInReceiptFromIframe() {
            modalBusinessReceiptPayment.value?.close();
            addPaymentInReceipt(tempAddPaymentInReceiptViewModel.value);
        }

        function updateRecipientName(recipientName: string) {
            invoiceReceipt.value.recipientName = recipientName;
        }
        function updateFor(forText: string) {
            invoiceReceipt.value.for = forText;
        }

        async function onMountedAction() {
            swal.showLoading();
            if (props.isNew) {
                const apiPromise = api.getInfoForReceipt(0, props.orderId);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data) {
                    swal.close();
                    return;
                }
                includeVat.value = apiResult.data.includeVat;
                invoiceReceipt.value.orderId = props.orderId;
                invoiceReceipt.value.for = t('invoice-receipt.order-number') + apiResult.data.orderNumber.toString();
                isTerminalPaymentOnline.value = apiResult.data.isTerminalPaymentOnline;
                invoiceReceipt.value.recipientName = apiResult.data.recipientName;
                invoiceReceipt.value.createdDate = apiResult.data.createdDate;
                localCurrencySymbol.value = apiResult.data.localCurrencySymbol;
                localCurrencyId.value = apiResult.data.localCurrencyId;
                orderLeftToPay.value = apiResult.data.leftToPay;
                orderCurrencyRate.value = apiResult.data.orderCurrencyRate;
                orderCurrencySymbol.value = apiResult.data.orderCurrencySymbol;
                orderCurrencyId.value = apiResult.data.orderCurrencyId;
            } else {
                const apiPromise = api.getInvoiceReceipt(props.invoiceReceiptId, props.editMode);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data) {
                    swal.close();
                    return;
                }
                includeVat.value = apiResult.data.invoiceReceipt.includeVat;
                invoiceReceipt.value = apiResult.data.invoiceReceipt;
                localCurrencySymbol.value = apiResult.data.localCurrencySymbol;
            }
            loaded.value = true;
            swal.close();
        }
        onMounted(onMountedAction);
        return {
            invoiceReceipt,
            loaded,
            cancel,
            submitForm,
            modalBusinessReceiptPayment,
            modalBusinessReceiptPelecardPayment,
            localCurrencySymbol,
            openPaymentModal,
            localCurrencyId,
            addPaymentInReceipt,
            removeCashPayment,
            removeCheckPayment,
            removeBankTransferPayment,
            editCashPayment,
            editCheckPayment,
            editBankTransferPayment,
            getCurrencySymbol,
            paymentsExist,
            paymentsTotal,
            frameUrl,
            shareDocument,
            viewPdfDocument,
            updateFor,
            updateRecipientName,
            invoiceReceiptHeader,
            orderLeftToPayComputed,
            orderCurrencySymbol,
            clearCreditCard,
            addPaymentInReceiptFromIframe,
            tempAddPaymentInReceiptViewModel,
            isTerminalPaymentOnline,
            paymentCreditCardExist,

            // NEW
            includeVat,
            vatPercent,
            getVatAmount,
            getTotalExcludedVat
        };
    }
});
